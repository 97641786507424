<template>
    <div class="home-index link-color-b">
            
        <el-tabs tab-position="left">
            <el-tab-pane label="Asosiy sozlamalar">
                <div class="d-flexk a-i-center mb20">
                    <div class="title-internal-block">Tizimdagi asosiy sozlamalar</div>
                </div>
                <el-row :gutter="10">
                    <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="6">
                        <router-link :to="{ name: 'medicalDirection' }">
                            <div class="settings-card card income-card">
                                <div>{{ $t("message.medical_directions") }}</div>
                                <i class="el-icon-s-claim"></i>
                            </div>
                        </router-link>
                    </el-col>
                    <!-- end el-col -->

                    <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="6">
                        <router-link :to="{ name: 'medicalOrganizationType' }">
                            <div class="settings-card card income-card">
                                <div>Tibbiy tashkilot turi</div>
                                <i class="el-icon-first-aid-kit"></i>
                            </div>
                        </router-link>
                    </el-col>
                    <!-- end el-col -->

                    <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="6">
                        <router-link :to="{ name: 'documentType' }">
                            <div class="settings-card card income-card">
                                <div>Hujjat turi</div>
                                <i class="el-icon-finished"></i>
                            </div>
                        </router-link>
                    </el-col>
                    <!-- end el-col -->

                    <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="6">
                        <router-link :to="{ name: 'resultsOfTheApplications' }">
                            <div class="settings-card card income-card">
                                <div>Murojjat natijalari</div>
                                <i class="el-icon-folder-add"></i>
                            </div>
                        </router-link>
                    </el-col>
                    <!-- end el-col -->

                    <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="6">
                        <router-link :to="{ name: 'servicesOfSetting' }">
                            <div class="settings-card card income-card">
                                <div>Xizmatlar</div>
                                <i class="el-icon-document-checked"></i>
                            </div>
                        </router-link>
                    </el-col>
                    <!-- end el-col -->

                    <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="6">
                        <router-link :to="{ name: 'licenseProcedures' }">
                            <div class="settings-card card income-card">
                                <div>Litsenziya talablari ixtisosligi</div>
                                <i class="el-icon-document-add"></i>
                            </div>
                        </router-link>
                    </el-col>
                    <!-- end el-col -->

                    <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="6">
                        <router-link :to="{ name: 'licenseProceduresConnection' }">
                            <div class="settings-card card income-card">
                                <div>Litsenziya talablar bog'lash</div>
                                <i class="el-icon-connection"></i>
                            </div>
                        </router-link>
                    </el-col>
                    <!-- end el-col -->

                     <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="6">
                        <router-link :to="{ name: 'statementType' }">
                            <div class="settings-card card income-card">
                                <div>Ariza turi</div>
                                <i class="el-icon-set-up"></i>
                            </div>
                        </router-link>
                    </el-col>
                    <!-- end el-col -->

                    <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="6">
                        <router-link :to="{ name: 'licenseRequirements' }">
                            <div class="settings-card card income-card">
                                <div>{{ $t('message.Obtaining a license based on requirements') }}</div>
                                <i class="el-icon-thumb"></i>
                            </div>
                        </router-link>
                    </el-col>
                    <!-- end el-col -->
                </el-row>
            </el-tab-pane>  

            <el-tab-pane label="Texnika va Uskuna  sozlamalar">
                <div class="d-flexk a-i-center mb20">
                    <div class="title-internal-block">Tizimdagi Texnika va Uskuna  sozlamalar</div>
                </div>
                <el-row :gutter="10">
                    <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="6">
                        <router-link :to="{ name: 'techniqueType' }">
                            <div class="settings-card card income-card">
                                <div>Texnika turi</div>
                                <i class="el-icon-cpu"></i>
                            </div>
                        </router-link>
                    </el-col>

                    <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="6">
                        <router-link :to="{ name: 'equipmentType' }">
                            <div class="settings-card card income-card">
                                <div>Uskuna turi</div>
                                <i class="el-icon-cpu"></i>
                            </div>
                        </router-link>
                    </el-col>
                </el-row>
                <!-- end el-row -->
            </el-tab-pane>

            <el-tab-pane label="Daraja sozlamalar">
                <div class="d-flexk a-i-center mb20">
                    <div class="title-internal-block">Tizimdagi Darajalar  sozlamalari</div>
                </div>
                <el-row :gutter="10">
                    <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="6">
                        <router-link :to="{ name: 'academicDegrees' }">
                            <div class="settings-card card income-card">
                                <div>Ilmiy darajasi</div>
                                <i class="el-icon-suitcase"></i>
                            </div>
                        </router-link>
                    </el-col>

                    <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="6">
                        <router-link :to="{ name: 'condition' }">
                            <div class="settings-card card income-card">
                                <div>Holati</div>
                                <i class="el-icon-paperclip"></i>
                            </div>
                        </router-link>
                    </el-col>

                    <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="6">
                        <router-link :to="{ name: 'qualificationLevels' }">
                            <div class="settings-card card income-card">
                                <div>Malaka darajasi</div>
                                <i class="el-icon-suitcase"></i>
                            </div>
                        </router-link>
                    </el-col>

                    <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="6">
                        <router-link :to="{ name: 'qualificationCategories' }">
                            <div class="settings-card card income-card">
                                <div>Malaka Toifasi</div>
                                <i class="el-icon-suitcase"></i>
                            </div>
                        </router-link>
                    </el-col>
                    <!-- end el-col -->
                </el-row>
            </el-tab-pane>

            <el-tab-pane label="Ijtimoyi sozlamalar">
                <div class="d-flexk a-i-center mb20">
                    <div class="title-internal-block">Tizimdagi Ijtimoyi  sozlamalar</div>
                </div>
                <el-row :gutter="10">
                    <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="6">
                        <router-link :to="{ name: 'employeesType' }">
                            <div class="settings-card card income-card">
                                <div>Xodimlar turlari</div>
                                <i class="el-icon-user"></i>
                            </div>
                        </router-link>
                    </el-col>
                    <!-- end el-col -->

                    <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="6">
                        <router-link :to="{ name: 'citizenship' }">
                            <div class="settings-card card income-card">
                                <div>Fuqarolik</div>
                                <i class="el-icon-notebook-1"></i>
                            </div>
                        </router-link>
                    </el-col>
                    <!-- end el-col -->

                    <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="6">
                        <router-link :to="{ name: 'nation' }">
                            <div class="settings-card card income-card">
                                <div>Millati</div>
                                <i class="el-icon-s-check"></i>
                            </div>
                        </router-link>
                    </el-col>
                    <!-- end el-col -->

                    <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="6">
                        <router-link :to="{ name: 'education' }">
                            <div class="settings-card card income-card">
                                <div>Oliy o'quv yurtlari</div>
                                <i class="el-icon-suitcase"></i>
                            </div>
                        </router-link>
                    </el-col>
                    <!-- end el-col -->

                    <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="6">
                        <router-link :to="{ name: 'propertyOwnership' }">
                            <div class="settings-card card income-card">
                                <div>Mulk egaligi</div>
                                <i class="el-icon-collection-tag"></i>
                            </div>
                        </router-link>
                    </el-col>
                    <!-- end el-col -->
                </el-row>
            </el-tab-pane>

            <el-tab-pane label="Xududiy sozlamalar">
                <div class="d-flexk a-i-center mb20">
                    <div class="title-internal-block">Tizimdagi Xududiy  sozlamalar</div>
                </div>
                <el-row :gutter="10">
                    <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="6">
                        <router-link :to="{ name: 'province' }">
                            <div class="settings-card card income-card">
                                <div>Viloyat</div>
                                <i class="el-icon-map-location"></i>
                            </div>
                        </router-link>
                    </el-col>
                    <!-- end el-col -->

                    <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="6">
                        <router-link :to="{ name: 'cityDistricts' }">
                            <div class="settings-card card income-card">
                                <div>Shahar</div>
                                <i class="el-icon-map-location"></i>
                            </div>
                        </router-link>
                    </el-col>
                    <!-- end el-col -->

                      <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="6">
                        <router-link :to="{ name: 'regionalSetting' }">
                            <div class="settings-card card income-card">
                                <div>Xududiy sozlama</div>
                                <i class="el-icon-discover"></i>
                            </div>
                        </router-link>
                    </el-col>
                    <!-- end el-col -->
                </el-row>
                <!-- end el-row -->
            </el-tab-pane>

            <el-tab-pane label="Moliyavi sozlamalar">
                <div class="d-flexk a-i-center mb20">
                    <div class="title-internal-block">Tizimdagi Moliyavi  sozlamalar</div>
                </div>
                <el-row :gutter="10">
                    <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="6">
                        <router-link :to="{ name: 'currencyType' }">
                            <div class="settings-card card income-card">
                                <div>Valyuta turi</div>
                                <i class="el-icon-aim"></i>
                            </div>
                        </router-link>
                    </el-col>
                    <!-- end el-col -->
                </el-row>
            </el-tab-pane>
        </el-tabs>   
    </div>
</template>
<script>
    export default {
        name: "",
        data() {
            return {
            };
        },
    };
</script>
<style>
.settings-card i{
    font-size: 50px;
    position: absolute;
    right: 0px !important;
    top: 0px !important;
    color: #dfdfdf;
}
.settings-card.card.income-card:hover i{
    color: #fff !important;
}
</style>